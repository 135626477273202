import React from 'react'
import style from './index.module.less'
import {sale_man} from '@/images/beampro/index'
import { useIntl } from 'react-intl'
import useBigScreen from "@/hooks/bigScreen"
import useNavigate from "@/hooks/useNavigate";
import getCountry from '@/utils/getCountry.js'

const SaleBox =()=>{
    const intl = useIntl()
    const ifPc = useBigScreen(768)
    const region = getCountry()
    const goLongTerm = ()=>{
        if(region=='jp'){
            window.open('https://jp.shop.xreal.com/en/products/xreal-beam-pro')
        }else{
            useNavigate('/long-term');
        }
        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: 20070
            }
        });
      
    }
    return(
        <div className={style.box} onClick={goLongTerm}>
            <div className={style.sale}>
                <img src={sale_man} />
                {/* {ifPc? <img src={sale_man} />:<div className={style.salePic}style={{backgroundImage:`url(${sale_man})`}}></div>} */}
                <div className={style.saleBox}>
                    <div className={style.saleBoxTitle}>{intl.formatMessage({ id: "longterm.banner.t" })}</div>
                    <div className={style.saleBoxTip} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `longterm.banner.info`})}` }}></div>
                </div>
            </div>
        </div>
    )
}
export default SaleBox
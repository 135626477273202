import React , {useEffect} from "react";
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIntl } from "react-intl"

const ThreeLineText = () => {
    gsap.registerPlugin(ScrollTrigger)
    const intl = useIntl()
    
    useEffect(()=>{
        const textboxes = gsap.utils.toArray('.beampro-threelinetext-item');
        textboxes.forEach(item => {
            gsap.fromTo(item, {
                opacity: 0,
                y: 80,
                scale:0.8
            },
                {
                    scale:1,
                    opacity: 1,
                    y: 0,
                    duration: 0.3,
                    ease: 'power1.out',
                    scrollTrigger: {
                        trigger: item,
                        start: "top 85%",
                        end: "top 85%",
                        toggleActions: "play none none reverse",
                    }
                }
        
            )
        });
    },[])

    return (
        <div className={style.box}>
            <div className={'beampro-threelinetext-item'} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `beampro_part2_title1` })}` }}>
            </div>
            <div className={'beampro-threelinetext-item'} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `beampro_part2_title2` })}` }}>
            </div>
            <div className={'beampro-threelinetext-item'} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `beampro_part2_title3` })}` }}>
            </div>
        </div>
    )
}

export default ThreeLineText
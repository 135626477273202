import React, { useEffect, useRef } from 'react'
import style from './index.module.less'
import BeamProLogo from '@/images/SVG/xreal_beampro_logo.svg'
import { beampro_model ,beampro_model_mobile} from '@/images/beampro/index'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import fadeInUp from '@/hooks/fadeInUp.js'
import { useIntl } from "react-intl"
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import useNavigate from '@/hooks/useNavigate'
import { Red_Button } from '@/new_components/index'
import useBigScreen from "@/hooks/bigScreen"
import { useGTM } from '@/hooks/useGTM.js'

const Beampro_two = () => {
    gsap.registerPlugin(ScrollTrigger)
    const ifPc =useBigScreen(768)
    const region = getCountry()
    const titleRef = useRef()
    const intl = useIntl()
    const price_diff = ['uk', 'de-en', 'it-en', 'nl-en', 'cz-en', 'fr-en', 'es-en']
    useEffect(() => {
        if (!!titleRef) {
          
            fadeInUp(titleRef.current, titleRef.current)

        }



    }, [])

    const goLongTerm = () => {
        if (region == 'jp') {
            window.open('https://jp.shop.xreal.com/en/products/xreal-beam-pro')
        } else {
            useNavigate('/long-term');
        }

        typeof sensors !== "undefined" && sensors.track('module_interaction', {
            custom: {
                module_id: 20074
            }
        });
    }
    return (
        <div className={style.box} >
            <div className={style.title} ref={titleRef}>
                <BeamProLogo />
                <div className={style.desc}>{intl.formatMessage({ id: "beampro_part1_desc" })}</div>
                {(region !== 'kr' && region !== 'th') && <Red_Button onClick={() => {
                    window.open(buy_link[region]['beampro'].shop)
                    typeof sensors !== "undefined" && sensors.track('module_interaction', {
                        custom: {
                            module_id: 20068
                        }
                    });

                    useGTM('products_click','BUY NOW','XREAL Beam Pro','promotion_XREAL Beam Pro')
                }}>BUY NOW</Red_Button>}
            </div>
            <div className={style.model}>
                <div className={style.price}  >
                    <div className={style.priceDesc}>
                        {price_diff.includes(region) ? intl.formatMessage({ id: `beampro_part1_price_${region}` }) : intl.formatMessage({ id: `beampro_part1_price` })}
                    </div>
                    {region=='jp'&& <div className={style.priceSale}>
                        <div>{price_diff.includes(region) ? intl.formatMessage({ id: `beampro_part1_sale_${region}` }) : intl.formatMessage({ id: `beampro_part1_sale` })} </div>
                        <div className={style.buyPriceSale} onClick={() => {
                            goLongTerm()

                        }}>  {intl.formatMessage({ id: `learn_more` })} </div>
                    </div>}
                   
                </div>
                {/* <div className={style.modelBg} style={{ backgroundImage: `url(${beampro_model})` }}></div> */}
           
                <img src={ifPc?beampro_model:beampro_model_mobile} className={style.modelBg}/>
            </div>
        </div>
    )
}

export default Beampro_two
import React, { useEffect, useRef, useState } from "react";
import {
    companion_pic1,
    companion_pic2,
    companion_pic3,
    companion_pic4,
    companion_pic5,
} from '@/images/beampro/index'
import {
    displayVideo1, displayVideo2, displayVideo3,
} from '@/images/air2/index'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import fadeInUp from '@/hooks/fadeInUp.js'
import classNames from "classnames";
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"
import getCountry from '@/utils/getCountry.js'

const Immersion = () => {
    const [ifHoverCompanion, setIfHoverCompanion] = useState(false)
    const titleRef = useRef()
    const companionTitleRef = useRef()
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const region = getCountry()

    const type_list = [

        {
            title: region == 'jp' ? '3DoFモード' : "Body Anchor",
            video: displayVideo2
        },
        {
            title: region == 'jp' ? 'ブレ補正モード' : "Smooth Follow",
            video: displayVideo3
        }
    ]

    const companion_list = [
        {
            img: companion_pic1,
            title: intl.formatMessage({ id: `beampro_part6_intro1_title` }),
            desc: intl.formatMessage({ id: `beampro_part6_intro1_desc` })
        },
        {
            img: companion_pic2,
            title: intl.formatMessage({ id: `beampro_part6_intro2_title` }),
            desc: intl.formatMessage({ id: `beampro_part6_intro2_desc` })
        },
        {
            img: companion_pic3,
            title: intl.formatMessage({ id: `beampro_part6_intro3_title` }),
            desc: intl.formatMessage({ id: `beampro_part6_intro3_desc` })
        },
        {
            img: companion_pic4,
            title: intl.formatMessage({ id: `beampro_part6_intro4_title` }),
            desc: intl.formatMessage({ id: `beampro_part6_intro4_desc` })
        },
        {
            img: companion_pic5,
            title: intl.formatMessage({ id: `beampro_part6_intro5_title` }),
            desc: intl.formatMessage({ id: `beampro_part6_intro5_desc` })
        }
    ]
    useEffect(() => {

        if (!!titleRef) {
            if (ifPc) {
                fadeInUp(titleRef.current, titleRef.current)
                fadeInUp(companionTitleRef.current, companionTitleRef.current)

                const typeItems = gsap.utils.toArray('.beampro_immersion_typeItem');
                typeItems.forEach(item => {
                    fadeInUp(item, item)
                })
            } else {
                gsap.killTweensOf(companionTitleRef.current)
                gsap.set(companionTitleRef.current, {
                    opacity: 1,
                    y: 0
                })
            }

        }
    }, [ifPc])

    return (
        <>
            <div className='beampro-progress-3'>
                <div className={style.box}>
                    <div ref={titleRef}>
                        <div className={style.title}>
                            {intl.formatMessage({ id: `beampro_part6_text1` })}
                        </div>
                        <div className={style.desc}>
                            {intl.formatMessage({ id: `beampro_part6_text2` })}

                        </div>

                    </div>
                    <div className={style.type} >
                        {type_list.map((item, index) => (
                            <div className={classNames(style.typeItem, 'beampro_immersion_typeItem')} key={`immersion_type_${index}`}>
                                <div className={style.typeItemVideo}>
                                    <video autoPlay playsInline loop muted width="100%" >
                                        <source src={item.video} type="video/mp4" />
                                    </video>
                                </div>
                                <div className={style.typeItemTitle}>{item.title}</div>
                            </div>
                        ))}
                    </div>


                </div>
            </div>
            <div className='beampro-progress-4'>
                <div className={style.companion}>
                    <div className={style.companionTitle} ref={companionTitleRef}>
                        {intl.formatMessage({ id: `beampro_part6_text3` })}
                    </div>
                    {ifPc ? <div className={style.companionBox}>
                        {companion_list.map((item, index) => (
                            <div className={style.companionBoxItem} key={`companionBoxItem${index}`}
                                onMouseEnter={() => setIfHoverCompanion(true)}
                                onMouseLeave={() => setIfHoverCompanion(false)}
                                style={{ flex: !ifHoverCompanion && index == 0 ? 15 : 1 }}
                            >
                                <div className={style.companionBoxItemPic} style={{ backgroundImage: `url(${item.img})` }}>

                                </div>
                                <div className={style.companionBoxItemText} style={{ opacity: !ifHoverCompanion && index == 0 ? 1 : 0 }}>
                                    <div className={style.companionBoxItemTextTitle}>{item.title}</div>
                                    <div className={style.companionBoxItemTextDesc}>{item.desc}</div>
                                </div>
                            </div>
                        ))}
                    </div> :
                        <div className={style.companionBoxM}>
                            {companion_list.map((item, index) => (
                                <div className={style.companionBoxMItem} key={`companionBoxMIem${index}`}>
                                    <div className={style.companionBoxMItemPic} style={{ backgroundImage: `url(${item.img})` }}></div>
                                    <div className={style.companionBoxMItemTitle}>
                                        {item.title}
                                    </div>
                                    <div className={style.companionBoxMItemDesc}>
                                        {item.desc}
                                    </div>
                                </div>))}
                        </div>}

                </div>
            </div>

        </>

    )
}
export default Immersion
import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import style from './index.module.less'
import {
    beampro_3d_pic, beampro_3d_beampro, beampro_3d_video1,
    beampro_3d_video2,
    beampro_3d_hand
} from '@/images/beampro/index'
import { FadeInBox } from '@/new_components/index'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"
import classNames from 'classnames'

const Video3D = () => {
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const picRef = useRef()
    const picBox2Ref = useRef()
    const picBox2TextRef = useRef()
    useEffect(() => {
        if (!!picBox2Ref.current) {
            gsap.to(picBox2Ref.current, {
                opacity: 1,
                ease: 'power1.out',
                scrollTrigger: {
                    trigger: picRef.current,
                    start: "top top",
                    end: "27% top",
                    scrub: true
                }
            })
            gsap.to(picBox2TextRef.current, {
                color: '#fff',
                ease: 'power1.out',
                scrollTrigger: {
                    trigger: picRef.current,
                    start: "27% top",
                    end: "bottom bottom",
                    scrub: true
                }
            })
            const textBoxes = gsap.utils.toArray('.beampro_3d_textItem')
            textBoxes.forEach(item=>{
                gsap.fromTo(item, {
                    opacity: 0,
                    y: 80,
                    scale:0.8
                },
                    {
                        scale:1,
                        opacity: 1,
                        y: 0,
                        duration: 0.5,
                        ease: 'power1.out',
                        scrollTrigger: {
                            trigger: item,
                            start: "top 85%",
                            end: "top 85%",
                            toggleActions: "play none none reverse",
                        }
                    }
            
                )
            })
        }

    }, [])
    return (
        <div>
            <FadeInBox backgroundImg={beampro_3d_pic} text={intl.formatMessage({ id: `beampro_part5_text1` })} mobileImgPosition={'70%'}/>
            <div className={style.pic} ref={picRef}>
                <div className={style.picBox}>
                    <div className={style.picContent1} style={{ backgroundImage: `url(${beampro_3d_beampro})` }}></div>
                    <div className={style.picContent2} ref={picBox2Ref}>
                        <div className={style.picContent2Box} style={{ backgroundImage: `url(${beampro_3d_beampro})` }}>
                            <div className={style.picContent2Text} ref={picBox2TextRef}>
                                {intl.formatMessage({ id: `beampro_3d_text1` })}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className={style.video}>
                {ifPc ? <div className={style.videoBox1}>
                    <div className={style.videoBox1Content}>
                        <video autoPlay playsInline loop muted width="100%" >
                            <source src={beampro_3d_video1} type="video/mp4" />
                        </video>
                    </div>
                    <div className={style.videoBox1Under}>
                        <div className={style.videoBox1UnderImg}>
                            <img src={beampro_3d_hand} />
                            <div className={style.videoBox1UnderImgContent}>
                                <video autoPlay playsInline loop muted width="100%" >
                                    <source src={beampro_3d_video2} type="video/mp4" />
                                </video>
                            </div>
                        </div>

                        <div className={style.videoBox1Text}>
                            {intl.formatMessage({ id: `beampro_3d_text2` })}
                        </div>
                    </div>
                </div> :
                    <div className={style.videoBox1M}>
                        <div className={style.videoBox1Text}>
                            {intl.formatMessage({ id: `beampro_3d_text2` })}
                        </div>
                        <div className={style.videoBox1MVideo}>
                            <video autoPlay playsInline loop muted width="100%" >
                                <source src={beampro_3d_video1} type="video/mp4" />
                            </video>
                        </div>
                        <div className={style.videoBox1MHand}>
                            <img src={beampro_3d_hand} />
                            <div className={style.videoBox1MHandVideo}>
                                <video autoPlay playsInline loop muted width="100%" >
                                    <source src={beampro_3d_video2} type="video/mp4" />
                                </video>
                            </div>
                        </div>

                    </div>}

                <div className={style.videoBox2}>
                    <div className={classNames(style.videoBox2Text1,'beampro_3d_textItem')}>  {intl.formatMessage({ id: `beampro_3d_text3` })}</div>
                    <div className={classNames(style.videoBox2Text2,'beampro_3d_textItem')}>  {intl.formatMessage({ id: `beampro_3d_text4` })}</div>
                </div>
            </div>
        </div>
    )
}
export default Video3D
import React, { useEffect, useRef } from "react";
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"
import { nebulaOS_bg, entertainment_girl } from '@/images/beampro/index'
import { useIntl } from "react-intl"
import { FadeInBox } from '@/new_components/index'

const Entertainment = () => {
    gsap.registerPlugin(ScrollTrigger)
    const intl = useIntl()
    const titleRef = useRef()
    const iconBoxRef=useRef()
    const googleBoxRef = useRef()
    const nebulaOSRef = useRef()
    const nebulaOSBoxRef = useRef()
    const ifPc = useBigScreen(768)
    let google_img_arr = []
    for (var i = 1; i < 15; i++) {
        let img = `${process.env.resourceUrl}images/beampro/google/googleapp_${i}.png`
        google_img_arr.push(img)
    }

   useEffect(() => {
        if (!!titleRef) {
     
            if (!!ifPc) {
                gsap.fromTo(nebulaOSBoxRef.current, {backgroundSize:'100%'},{
                    backgroundSize: '150%',
                    scrollTrigger: {
                        trigger: nebulaOSBoxRef.current,
                        start: "top center",
                        end: "bottom center",
                        scrub: true
                    },
                })
                gsap.to(iconBoxRef.current, {
                    opacity: 1,
                    scale:1,
                    ease: "power1.out",
                    scrollTrigger: {
                        trigger: googleBoxRef.current,
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true
                    },
                })
            } else {
        
                gsap.killTweensOf(nebulaOSBoxRef.current,iconBoxRef.current);
                gsap.to(iconBoxRef.current, {
                    opacity: 1,
                    scale:1,
                    ease: "power1.out",
                    scrollTrigger: {
                        trigger: googleBoxRef.current,
                        start: "top 40%",
                        end: "bottom bottom",
                        scrub: true
                    },
                })
                gsap.fromTo(nebulaOSBoxRef.current,{backgroundSize:"100%"}, {
                    backgroundSize: '150%',
                    scrollTrigger: {
                        trigger: nebulaOSBoxRef.current,
                        start: "top center",
                        end: "bottom center",
                        scrub: true
                    },
                })
               
            }


        }

    }, [ifPc])
 
    const google_img = [
        {
            top: '0%',
            left: '10%'
        },
        {
            top: '40%',
            left: '15%'
        },
        {
            top: '30%',
            left: '30%',
        }, {
            top: '70%',
            left: '3%'
        }, {
            top: '65%',
            left: '25%'
        }, {
            top: '80%',
            left: '40%',
        }, {
            top: '70%',
            left: '55%',
        }, {
            top: '30%',
            left: '60%'
        }, {
            top: '27%',
            left: '72%',

        }, {
            top: '63%',
            left: '70%'
        }, {
            bottom: '10%',
            right: '10%'
        },
        {
            top: '50%',
            right: '3%'
        },
        {
            top: '0%',
            right: '5%'
        },  {
            top: '12%',
            right: '45%'
        }
    ]
    const google_img_mobile = [
        {
            top: '-8%',
            left: '20%'
        },
        {
            top: '18%',
            left: '35%'
        },
        {
            top: '46%',
            left: '75%',
        }, {
            top: '47%',
            left: '-13%'
        }, {
            top: '10%',
            left: '60%'
        }, {
            top: '50%',
            left: '32%',
        }, {
            left: '5%',
            bottom: '0%'
        }, {
            bottom: '12%',
            left: '57%'
        }, {
            top: '70%',
            right: '0%',
        }, {
            top: '67%',
            left: '30%'
        }, {
            top: '14%',
            left: '0%'
        },{
            top: '27%',
            left: '14%'
        },{
            top: '19%',
            right: '-5%'
        },{
            top: '-3%',
            right: '0'
        }
    ]
    return (
        <div className={style.box} >
            {/* <FadeInBox backgroundImg={entertainment_girl} text={intl.formatMessage({ id: `beampro_part4_title` })} mobileImgPosition={'75%'}/> */}
            <div className={style.google}>
                <div className={style.title} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `beampro_google_play` })}` }}></div>
                <div className={style.googleBox} ref={googleBoxRef}>
                    <div className={style.nebulaOS} >
                        <div className={style.nebulaOSbg} style={{ backgroundImage: `url(${nebulaOS_bg})` }} ref={nebulaOSBoxRef}>
                            <div className={style.nebulaOSCover} ref={nebulaOSRef}>nebula<div className={style.nebulaOSCoverClip}>OS</div></div>
                        </div>
                    </div>
                    <div className={style.icon} ref={iconBoxRef}>
                    {google_img_arr.map((item,index)=>(
                        <img className={style.iconItem} src={item}  key={`beampro_entertainment_google_img${index}`} style={ifPc?google_img[index]:google_img_mobile[index]}/>
                    ))}
                    </div>
                </div>
            </div>

     

            {/* 放到下一模块里 */}
            {/* <div className={style.box2} ref={choicesBoxRef}>
                <div className={style.box2Text} ref={choicesBoxItem1Ref}>
                    <div className={style.box2TextTitle}>{intl.formatMessage({ id: `beampro_part4_text1` })}</div>
                    <div className={style.box2TextDesc}>{intl.formatMessage({ id: `beampro_part4_text2` })}</div>
                  
                </div>
                <div className={style.box2Pic}>
                    <div className={style.box2PicLeft} ref={choicesBoxItem2Ref} style={{ backgroundImage: `url(${entertainment_left})` }}>

                    </div>
                    <div className={style.box2PicRight} ref={choicesBoxItem3Ref}>
                        <video autoPlay playsInline loop muted width="100%" >
                            <source src={entertainment_video} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div> */}


        </div>
    )
}

export default Entertainment
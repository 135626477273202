import React, { useState } from 'react';
import { Layout, SEO, ProcessNav } from '@/components/index'
import { ProBanner, FadeInBox } from '@/new_components/index'
import { Header, Beampro_two, ThreeLineText, HighLights, Entertainment, Moment, Immersion, Guide, Choose, Specs, SaleBox, Use, Video3D } from '@/new_components/BeamPro'
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import { useInView } from "react-intersection-observer"
import BeamProLogo from '@/images/SVG/xreal_beampro_logo.svg'
import { entertainment_girl } from '@/images/beampro/index'
import { useIntl } from 'react-intl';

const Index = () => {
    const intl = useIntl()
    const region = getCountry()
    const [probannerType, setProbannerType] = useState('overview')

    const banner_intro = {
        name: 'XREAL BEAM PRO',
        buy_link: buy_link[region]['beampro'].shop,
        logo: <BeamProLogo />
    }

    const [block1Ref, block1InView] = useInView({ threshold: 0 });
    const [block2Ref, block2InView] = useInView({ threshold: 0 });
    const navList = [
        {
            id: 0,
            title: intl.formatMessage({ id: "progress_beampro_0" }),
            classname: "beampro-progress-0",
        },
        {
            id: 1,
            title: intl.formatMessage({ id: "progress_beampro_1" }),
            classname: "beampro-progress-1",
        }, {
            id: 2,
            title: intl.formatMessage({ id: "progress_beampro_2" }),
            classname: "beampro-progress-2",
        }, {
            id: 3,
            title: intl.formatMessage({ id: "progress_beampro_3" }),
            classname: "beampro-progress-3",
        }, {
            id: 4,
            title: intl.formatMessage({ id: "progress_beampro_4" }),
            classname: "beampro-progress-4",
        }, {
            id: 5,
            title: intl.formatMessage({ id: "progress_beampro_5" }),
            classname: "beampro-progress-5",
        }

    ]
    return (
        <Layout menuBlack={block1InView || !block2InView ? true : false}>
            <SEO page="XREAL Beam Pro" />
            <div style={{ position: "relative" }}>
                <ProcessNav list={navList}  />
                {probannerType == 'overview' ? <>
                    <div ref={block1Ref}>
                        <div className='beampro-progress-0'>
                            <Header />
                            <Beampro_two />
                            <ThreeLineText />
                            <HighLights />
                            <FadeInBox backgroundImg={entertainment_girl} text={intl.formatMessage({ id: `beampro_part4_title` })} mobileImgPosition={'75%'} />
                        </div>
                        <div className='beampro-progress-1'>
                            <Entertainment />
                            <Use />
                        </div>


                    </div>
                    <div ref={block2Ref}>
                        <div className='beampro-progress-2'>
                            <Video3D />
                            <Moment />
                        </div>

                    </div>

                    <Immersion />

                    <div className='beampro-progress-5'>
                        <Guide setProbannerType={setProbannerType} />
                        {region !== 'th' && region !== 'kr' && <> <Choose /> </>}
                        {region == 'jp' && <SaleBox />}
                    </div>

                </> : <Specs />}


                <ProBanner banner_intro={banner_intro} setProbannerType={setProbannerType} probannerType={probannerType} />

            </div>


        </Layout>
    )

}
export default Index
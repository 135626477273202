import React, { useEffect, useRef, useState } from "react";
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classNames from 'classnames'
import {
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logoplace,
    google1,
    google2,
    google3,
    google4,
    google5,
    google6,
    slogan1,
    slogan2,
    slogan3,
    slogan4,
    beampro_header_pro
} from '@/images/beampro'
import useBigScreen from "@/hooks/bigScreen"

const Header = () => {
    gsap.registerPlugin(ScrollTrigger)
    const boxRef = useRef()
    const boxRef_mobile = useRef()
    const stickyboxRef = useRef()
    const ifPc = useBigScreen(768)
    const [orientation, setOrientation] = useState(1)
    const animations = [
        { rotate: '-30deg', x: '-200%', y: '-100%' },
        { rotate: '20deg', x: '-50%', y: '-500%' },
        { rotate: '-10deg', x: '-100%', y: '-300%' },
        { rotate: '15deg', x: '-20%', y: '-350%' },//111
        { rotate: '25deg', x: '-40%', y: '-180%' },
        { rotate: '35deg', x: '-80%', y: '-10%' },
        { rotate: '50deg', x: '300%', y: '-300%' },
        { rotate: '-20deg', x: '200%', y: '700%' },//222
        { rotate: '45deg', x: '-250%', y: '350%' },
        { rotate: '15deg', x: '-300%', y: '-120%' },
        { rotate: '-25deg', x: '-200%', y: '120%' },
        { rotate: '-35deg', x: '-200%', y: '-240%' },//333
        { rotate: '20deg', x: '100%', y: '30%' },
        { rotate: '-20deg', x: '200%', y: '300%' },
        { rotate: '-10deg', x: '150%', y: '-150%' },
        { rotate: '-15deg', x: '-40%', y: '-120%' },
        { rotate: '-25deg', x: '-20%', y: '-180%' },
    ];
    const animation_mobile = [
        { rotate: '30deg', x: '-50%', y: '-400%' },
        { rotate: '-20deg', x: '50%', y: '-300%' },
        { rotate: '-10deg', x: '-50%', y: '300%' },
        { rotate: '15deg', x: '-20%', y: '-350%' },
        { rotate: '25deg', x: '-40%', y: '-180%' },
        { rotate: '35deg', x: '10%', y: '-400%' },
        { rotate: '50deg', x: '400%', y: '-100%' },
        { rotate: '-20deg', x: '-50%', y: '300%' },//222
        { rotate: '45deg', x: '50%', y: '250%' },
        { rotate: '15deg', x: '-300%', y: '-120%' },
        { rotate: '-25deg', x: '100%', y: '180%' }

    ]
    const animations_google = [
        { rotate: '-25deg', x: '100%', y: '-500%' },
        { rotate: '20deg', x: '100%', y: '300%' },
        { rotate: '-10deg', x: '0%', y: '-10%' },
        { rotate: '35deg', x: '0', y: '350%' },
        { rotate: '15deg', x: '-100%', y: '-500%' },
        { rotate: '-20deg', x: '150%', y: '-350%' },

    ];
    const animations_google_mobile = [
        { rotate: '-25deg', x: '-150%', y: '-100%' },
        { rotate: '20deg', x: '100%', y: '300%' },
        { rotate: '-10deg', x: '-100%', y: '-300%' },
        { rotate: '35deg', x: '-300%', y: '350%' },


    ];

    useEffect(() => {
        if (orientation == 1) {
            const logoboxes = gsap.utils.toArray('.beampro-logo');
            const googleboxes = gsap.utils.toArray('.beampro-google');
            const sloganboxes = gsap.utils.toArray('.beampro-slogan');

            const tl = gsap.timeline();
            const addAnimation = (logo, animation) => {
                tl.fromTo(logo, animation, {
                    rotate: 0,
                    x: 0,
                    y: 0,
                    duration: 0.5,
                    ease: 'power1.inOut',
                    delay: 1,
                }, 0);
            };
            const addAnimation_google = (logo, animation) => {
                tl.fromTo(logo, animation, {
                    rotate: 0,
                    x: 0,
                    y: 0,
                    duration: 0.5,
                    opacity: 0,
                    ease: 'power1.inOut',
                    delay: 1,
                }, 0);
            }

            logoboxes.forEach((logo, index) => {
                addAnimation(logo, animations[index]);
            });
            googleboxes.forEach((logo, index) => {
                addAnimation_google(logo, animations_google[index]);
            });
            logoboxes.forEach((logo) => {
                tl.to(logo, {
                    opacity: 0,
                    duration: 0.5,
                    ease: 'power1.inOut'
                }, 2)
            });
            googleboxes.forEach((logo) => {
                tl.to(logo, {
                    opacity: 0,
                    duration: 0.5,
                    ease: 'power1.inOut'
                }, 2)
            });
            sloganboxes.forEach(item => {
                tl.to(item, {
                    opacity: 1,
                    duration: 0.5,
                    ease: 'power1.inOut'
                }, 2)
            })
            tl.to('.beampro_header_pro', {
                opacity: 1,
                duration: 0.5,
                ease: 'power1.inOut'
            }, 2)
            tl.to('.beampro-slogan1', {
                x: '-30px',
                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    trigger: boxRef.current,
                    scrub: true,
                }
            })
            tl.to('.beampro-slogan2', {
                x: '30px',
                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    trigger: boxRef.current,
                    scrub: true,
                }
            })

            tl.to('.beampro-slogan3', {
                x: '-100px',
                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    trigger: boxRef.current,
                    scrub: true,
                }
            })
            tl.to('.beampro-slogan4', {
                x: '100px',
                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    trigger: boxRef.current,
                    scrub: true,
                }
            })
            tl.to('.beampro_header_pro', {
                y: '-50%',
                top: "50%",

                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    trigger: boxRef.current,
                    scrub: true,
                }
            })

        } else {
            const logoboxes_mobile = gsap.utils.toArray('.beampro_header_app_mobile');
            const googleboxes_mobile = gsap.utils.toArray('.beampro_header_app_mobile_google');
            const sloganboxes_mobile = gsap.utils.toArray('.mobile_beampro_head_slogan');

            const tl = gsap.timeline();
            const addAnimation = (logo, animation) => {
                tl.fromTo(logo, animation, {
                    rotate: 0,
                    x: 0,
                    y: 0,
                    duration: 0.5,
                    ease: 'power1.inOut',
                    delay: 1,
                }, 0);
            };
            const addAnimation_google = (logo, animation) => {
                tl.fromTo(logo, animation, {
                    rotate: 0,
                    x: 0,
                    y: 0,
                    duration: 0.5,
                    opacity: 0,
                    ease: 'power1.inOut',
                    delay: 1,
                }, 0);
            }

            logoboxes_mobile.forEach((logo, index) => {
                addAnimation(logo, animation_mobile[index]);
            });
            googleboxes_mobile.forEach((logo, index) => {
                addAnimation_google(logo, animations_google_mobile[index]);
            });
            logoboxes_mobile.forEach((logo) => {
                tl.to(logo, {
                    opacity: 0,
                    duration: 0.5,
                    ease: 'power1.inOut'
                }, 2)
            });
            googleboxes_mobile.forEach((logo) => {
                tl.to(logo, {
                    opacity: 0,
                    duration: 0.5,
                    ease: 'power1.inOut'
                }, 2)
            });
            sloganboxes_mobile.forEach(item => {
                tl.to(item, {
                    opacity: 1,
                    duration: 0.5,
                    ease: 'power1.inOut'
                }, 2)
            })
            sloganboxes_mobile.forEach(item => {
                tl.to(item, {
                    y: '-400%',
                    scrollTrigger: {
                        start: "top top",
                        end: "bottom bottom",
                        trigger: boxRef_mobile.current,
                        scrub: true,
                    }
                })
            })
            tl.to('.beampro_header_pro_mobile', {
                opacity: 1,
                duration: 0.5,
                ease: 'power1.inOut'
            }, 2)
        
            tl.to('.beampro_header_pro_mobile', {
                y: '-50%',
                top: "60%",

                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    trigger: boxRef_mobile.current,
                    scrub: true,
                }
            })
        }



    }, [orientation])

    useEffect(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setOrientation(width < height / 1.5 || width < 768 ? 0 : 1);


        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])
    const handleResize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setOrientation(width < height / 1.5 || width < 768 ? 0 : 1);
    };
    return (
        <>     {orientation == 1 ? <div className={style.box} ref={boxRef}>
            <div className={style.sticky} ref={stickyboxRef}>
                <div className={style.appbox}>
                    <div className={style.appboxOut}>
                        <div className={style.appboxItem}>
                            <img src={slogan1} className={classNames(style.slogan, 'beampro-slogan', 'beampro-slogan1')} />
                            <div className={style.appboxItemContent}>
                                <img src={logo1} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logo2} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logo3} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logoplace} className={style.logo} />
                                <img src={logo4} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={google1} className={classNames(style.logo, 'beampro-google')} />

                            </div>
                        </div>
                        <div className={style.appboxItem}>
                            <img src={slogan2} className={classNames(style.slogan, 'beampro-slogan', 'beampro-slogan2')} />
                            <div className={style.appboxItemContent}>
                                <img src={logo5} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logoplace} className={style.logo} />
                                <img src={logo6} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logo7} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logo8} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={google2} className={classNames(style.logo, 'beampro-google')} />

                            </div>
                        </div>
                    </div>
                    <div className={style.appboxOut}>
                        <div className={style.appboxItem}>
                            <img src={slogan3} className={classNames(style.slogan, 'beampro-slogan', 'beampro-slogan3')} />
                            <div className={style.appboxItemContent}>
                                <img src={logo9} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logoplace} className={style.logo} />
                                <img src={logo10} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logo11} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={google3} className={classNames(style.logo, 'beampro-google')} />
                                <img src={google4} className={classNames(style.logo, 'beampro-google')} />

                            </div>
                        </div>
                        <div className={style.appboxItem}>
                            <img src={slogan4} className={classNames(style.slogan, 'beampro-slogan', 'beampro-slogan4')} />
                            <div className={style.appboxItemContent}>
                                <img src={logo12} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logo13} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logo14} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={logoplace} className={style.logo} />
                                <img src={logo15} className={classNames(style.logo, 'beampro-logo')} />
                                <img src={google5} className={classNames(style.logo, 'beampro-google')} />
                                <img src={google6} className={classNames(style.logo, 'beampro-google')} />
                            </div>
                        </div>
                    </div>

                </div>
                <img src={beampro_header_pro} className={classNames(style.beampro, 'beampro_header_pro')} />
            </div>
        </div> :
            <div className={style.mobileBox} ref={boxRef_mobile}>
                <div className={style.mobileBoxSticky}>
                    <div className={style.mobileApp}>
                        <div className={style.mobileAppItem}>
                            <div className={classNames(style.mobileAppItemSlogan, 'mobile_beampro_head_slogan')}>
                                Millions of Apps
                            </div>
                            <div className={style.mobileAppItemBox}>
                                <img src={logo1} className={classNames(style.logo, 'beampro_header_app_mobile')} />
                                <img src={logo2} className={classNames(style.logo, 'beampro_header_app_mobile')} />
                                <img src={logo3} className={classNames(style.logo, 'beampro_header_app_mobile')} />
                                <img src={logo4} className={classNames(style.logo, 'beampro_header_app_mobile')} />
                                <img src={logo5} className={classNames(style.logo, 'beampro_header_app_mobile')} />
                                <img src={logo6} className={classNames(style.logo, 'beampro_header_app_mobile')} />
                                <img src={google3} className={classNames(style.googlelogo, 'beampro_header_app_mobile_google')} />
                                <img src={google4} className={classNames(style.googlelogo, 'beampro_header_app_mobile_google')} />



                            </div>
                        </div>
                        <div className={style.mobileAppItem}>
                            <div className={classNames(style.mobileAppItemSlogan, 'mobile_beampro_head_slogan')}>
                                in AR Glasses
                            </div>
                            <div className={style.mobileAppItemBox}>
                                <img src={logo7} className={classNames(style.logo2, 'beampro_header_app_mobile')} />
                                <img src={logo8} className={classNames(style.logo2, 'beampro_header_app_mobile')} />
                                <img src={logo9} className={classNames(style.logo2, 'beampro_header_app_mobile')} />
                                <img src={logo10} className={classNames(style.logo2, 'beampro_header_app_mobile')} />
                                <img src={logo11} className={classNames(style.logo2, 'beampro_header_app_mobile')} />
                                <img src={google1} className={classNames(style.googlelogo, 'beampro_header_app_mobile_google')} />
                                <img src={google2} className={classNames(style.googlelogo, 'beampro_header_app_mobile_google')} />
                            </div>
                        </div>
                    </div>
                    <img src={beampro_header_pro} className={classNames(style.beamproM, 'beampro_header_pro_mobile')} />
                </div>
            </div>}</>


    )
}
export default Header
import React, { useEffect, useRef } from "react";
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"

const FadeInBox = ({ backgroundImg, text ,mobileImgPosition}) => {
    gsap.registerPlugin(ScrollTrigger)

    const fadeinBoxRef = useRef()
    const fadeinTextBoxRef = useRef()
    const ifPc = useBigScreen(768)
    useEffect(() => {
        if (!!fadeinBoxRef.current) {
            gsap.to(fadeinTextBoxRef.current, {
                opacity: 1,
                duration: 0.3,
                scrollTrigger: {
                    start: "top 33%",
                    trigger: fadeinBoxRef.current,
                    toggleActions: "play none none reverse"
                }
            })
        }
    }, [])

    return (
        <div className={style.box} style={{ backgroundImage: `url(${backgroundImg})` ,backgroundPosition:mobileImgPosition&&!ifPc?mobileImgPosition:'center center'}} ref={fadeinBoxRef}>
            <div className={style.textBox} ref={fadeinTextBoxRef}>
                <div className={style.textBoxContent}>
                    {text}
                </div>
            </div>
        </div>
    )
}

export default FadeInBox
import React, { useEffect, useRef, useState } from "react";
import style from './index.module.less'
import {  enriching_mobile_jp,enriching_pc_jp,enriching_pc,enriching_mobile,user_guide,user_guide_poster } from '@/images/beampro/index'
import { useIntl } from "react-intl"
import useBigScreen from "@/hooks/bigScreen"
import getCountry from '@/utils/getCountry.js'
import {
    playBtn
} from '@/images/support/index'

const Guide = ({setProbannerType}) => {
    const intl=useIntl()
    const ifPc=useBigScreen(768)
    const region = getCountry()
    const [videoPlay , setVideoPlay]=useState(false)
    const videoRef = useRef(null)

    const playVideo = ()=>{
        setVideoPlay(true)
        videoRef.current.play()
    }
    return (
        <div className={style.box}>
            <div className={style.title}>
            {intl.formatMessage({ id: `beampro_part7_title` })}
                <div className={style.specs} onClick={()=>{
                    setProbannerType('specs');
                    typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
                        custom: {
                            module_id: 20069
                        }
                    });
                    }}>
                {intl.formatMessage({ id: `beampro_part7_spec` })}  →
                </div>
            </div>
            <div className={style.enriching} >
                <img src={ifPc?(region=='jp'?enriching_pc_jp:enriching_pc):(region=='jp'?enriching_mobile_jp:enriching_mobile)} />
            </div>

            <div className={style.guide}>
                <div className={style.guideTitle}>
                {intl.formatMessage({ id: `beampro_part8_title` })}
                </div>
                <div className={style.guideVideo} >
                {!videoPlay&& <img style={{opacity:videoPlay?0:1}} src={playBtn} className={style.playIcon} onClick={()=>playVideo()} />} 
                    <video 
                    autoPlay={false}
                    width="100%" 
                    controls={videoPlay} 
                    playsInline 
                    poster={user_guide_poster}
                    ref={videoRef}
                    >
                        <source src={user_guide} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}

export default Guide
import React, { useEffect, useRef } from "react";
import {
    sofa3d_video, moment_black_record,
    compare_1_1,
    compare_1_2,
    compare_2_1,
    compare_2_2,
    moment_beam,
    moment_beam_line,
    moment_beam_50,
    moment_beam_mobile,
    moment_beam_line_mobile,
    moment_beam_50_mobile,
} from '@/images/beampro/index'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import fadeInUp from '@/hooks/fadeInUp.js'
import classNames from "classnames";
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"

const Moment = () => {
    gsap.registerPlugin(ScrollTrigger)
    const sofaTextRef = useRef()
    const sofacoverRef = useRef()
    const beamBoxRef = useRef()
    const beamText1Ref = useRef()
    const beamText2Ref = useRef()
    const beamBox1Ref = useRef()
    const beamBox2Ref = useRef()
    const beamBox50mmRef = useRef()
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const compare_list = [
        {
            img1: compare_2_1,
            img2: compare_2_2,
            title: intl.formatMessage({ id: "beampro_part5_text9" }),
            desc: intl.formatMessage({ id: "beampro_part5_text10" }),
            desc1: intl.formatMessage({ id: "beampro_part5_text13" }),
            desc2: intl.formatMessage({ id: "beampro_part5_text14" }),
        }, {
            img1: compare_1_1,
            img2: compare_1_2,
            title: intl.formatMessage({ id: "beampro_part5_text11" }),
            desc: intl.formatMessage({ id: "beampro_part5_text10" }),
            desc1: intl.formatMessage({ id: "beampro_part5_text15" }),
            desc2: intl.formatMessage({ id: "beampro_part5_text16" }),
        }
    ]
    useEffect(() => {
        if (!!beamBoxRef) {
            gsap.fromTo(sofacoverRef.current, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.2,
                ease: 'power1.out',
                scrollTrigger: {
                    trigger: sofaTextRef.current,
                    start: "top bottom",
                    end: "top bottom",
                    toggleActions: "play none none reverse",
                }
            })

            //下面黑背景渐现
            const blackBoxArr = gsap.utils.toArray('.beampro_moment_black_fade')
            blackBoxArr.forEach(item => {
                fadeInUp(item, item)
            })

            //beam线框动画= =
            const tl = gsap.timeline()
            gsap.fromTo(beamText1Ref.current, {
                opacity: 0,
                y: 60
            }, {
                opacity: 1,
                y: 0,
                duration: 0.2,
                ease: 'power1.out',
                scrollTrigger: {
                    trigger: beamBoxRef.current,
                    start: "top top",
                    // end: "25% top",
                    toggleActions: "play none none reverse",
                }
            })
            gsap.fromTo(beamBox1Ref.current, {
                opacity: 1,
                height: '100vh'
            }, {
                // opacity: 0,
                height: '0',
                ease: 'power1.out',
                scrollTrigger: {
                    trigger: beamBoxRef.current,
                    start: "8% top",
                    end: "40% bottom",
                    scrub: true
                }
            })
            gsap.fromTo(beamText2Ref.current, {
                opacity: 0,
                y: 60
            }, {
                opacity: 1,
                y: 0,
                duration: 0.2,
                ease: 'power1.out',
                scrollTrigger: {
                    trigger: beamBoxRef.current,
                    start: "40% bottom",
                    end: "56% bottom",
                    toggleActions: "play reverse play reverse",
                }
            })
            if (ifPc) {
                gsap.to(beamBox2Ref.current, {
                    scale: 2,
                    ease: "power1.inOut",
                    scrollTrigger: {
                        trigger: beamBoxRef.current,
                        start: "56% bottom",
                        end: "72% bottom",
                        scrub: true
                    }
                });
            } else {
                gsap.killTweensOf(beamBox2Ref.current);
                gsap.to(beamBox2Ref.current, {
                    scale: 2.4,
                    ease: "power1.inOut",
                    scrollTrigger: {
                        trigger: beamBoxRef.current,
                        start: "56% bottom",
                        end: "72% bottom",
                        scrub: true
                    }
                });
            }

            gsap.fromTo(beamBox50mmRef.current, {
                opacity: 0,
                y: 60
            }, {
                opacity: 1,
                y: 0,
                duration: 0.3,
                ease: "power1.inOut",
                scrollTrigger: {
                    trigger: beamBoxRef.current,
                    start: "72% bottom",
                    end: "bottom bottom",
                    toggleActions: "play none none reverse",
                }
            });
        }

    }, [ifPc])
    return (
        <div className={style.box}>
            <div className={style.beam} ref={beamBoxRef}>
                <div className={style.beamSticky}>
                    <div className={style.beamBox1} ref={beamBox1Ref}>
                        <div className={style.beamBox1Pic} style={{ backgroundImage: `url(${ifPc ? moment_beam : moment_beam_mobile})` }}>
                            <div className={style.beamBox1Text} ref={beamText1Ref}>
                                <div className={style.beamBoxTitle}>
                                    {intl.formatMessage({ id: "beampro_part5_text2" })}
                                </div>
                                <div className={style.beamBoxDesc}>
                                    {intl.formatMessage({ id: "beampro_part5_text3" })}{intl.formatMessage({ id: "beampro_part5_text6" })}{intl.formatMessage({ id: "beampro_part5_text7" })}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={style.beamBox2}>
                        <div className={style.beamBox2Pic} ref={beamBox2Ref} style={{ backgroundImage: `url(${ifPc ? moment_beam_line : moment_beam_line_mobile})` }}>
                            <div className={style.beamBox2Text} ref={beamText2Ref}>
                                <div className={style.beamBoxTitle}>
                                    {intl.formatMessage({ id: "beampro_part5_text4" })}
                                </div>
                                <div className={style.beamBoxDesc}>
                                    {intl.formatMessage({ id: "beampro_part5_text5" })}
                                </div>
                            </div>
                            <div className={style.beamBox2_mm} style={{ backgroundImage: `url(${ifPc ? moment_beam_50 : moment_beam_50_mobile})` }} ref={beamBox50mmRef}>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className={style.black}>
                <div className={style.compare}>
                    <div className={style.compareTitle}>{intl.formatMessage({ id: "beampro_part5_text12" })}</div>
                    <div className={style.compareBox}>
                        {compare_list.map((item, index) => (
                            <div className={style.compareItem} key={`beampro_moment_black_fade${index}`}>
                                <div className={style.compareItemTitle}>
                                    {item.title}
                                </div>

                                <div className={style.compareItemBox}>
                                    <img src={item.img1} />
                                    <div className={style.compareItemBoxDesc}>
                                        {intl.formatMessage({ id: item.desc1 })}
                                    </div>
                                </div>
                                <div className={style.compareItemBox}>
                                    <img src={item.img2} />
                                    <div className={style.compareItemBoxDesc}>
                                        {intl.formatMessage({ id: item.desc2 })}
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
                <div className={style.sofa}>
                    <div className={style.sofaTitle}>{intl.formatMessage({ id: "beampro_part5_text8" })}</div>
                    <div className={style.sofaVideo}>
                        <video autoPlay playsInline loop muted width="100%" >
                            <source src={sofa3d_video} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
         
        </div>

    )
}

export default Moment
import React, { useEffect, useRef } from "react";
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"
import {
    highlight_video1,
    highlight_video2,
    highlight_video3,
    highlight_video4
} from '@/images/beampro/index'

const HighLights = () => {
    gsap.registerPlugin(ScrollTrigger)
    const intl = useIntl()

    const list_arr = [
        {
            video: highlight_video1,
            title: intl.formatMessage({ id: `beampro_part3_highlights_title1` }),
            desc: intl.formatMessage({ id: `beampro_part3_highlights_desc1` }),
        }, {
            video: highlight_video2,
            title: intl.formatMessage({ id: `beampro_part3_highlights_title2` }),
            desc: intl.formatMessage({ id: `beampro_part3_highlights_desc2` }),

        }, {
            video: highlight_video3,
            title: intl.formatMessage({ id: `beampro_part3_highlights_title3` }),
            desc: intl.formatMessage({ id: `beampro_part3_highlights_desc3` }),

        }, {
            video: highlight_video4,
            title: intl.formatMessage({ id: `beampro_part3_highlights_title4` }),
            desc: intl.formatMessage({ id: `beampro_part3_highlights_desc4` }),
            tip: intl.formatMessage({ id: `beampro_part3_highlights_tip4` })
        }
    ]

    const boxRef = useRef()
    const contentBoxRef = useRef()
    const outBoxRef = useRef()
    const titleRef = useRef()
    const ifPc = useBigScreen(768)



    return (
        <>
            {ifPc ?
                (<div className={style.box} >
                    <div className={style.title}>{intl.formatMessage({ id: `beampro_part3_highlights_title` })}</div>
                    <div className={style.content}>
                        {list_arr.map((item, index) => (
                            <div className={style.contentItem} key={`beampro_highlights${index}`}>
                                <div className={style.contentItemBox}>
                                    <div className={style.itemTitle}>
                                        {item.title}
                                    </div>
                                    <div className={style.itemDesc}>
                                        {item.desc}
                                    </div>
                                    {!!item.tip && <div className={style.itemTip}>
                                        {item.tip}
                                    </div>}

                                </div>


                                <div className={style.contentItemVideo}>
                                    <video autoPlay playsInline loop muted width="100%" >
                                        <source src={item.video} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>) :
                (<div className={style.boxMobile}>
                    <div className={style.title} >{intl.formatMessage({ id: `beampro_part3_highlights_title` })}</div>
                    <div className={style.boxMobileContent}>

                        {
                            list_arr.map((item, index) => (
                                <div className={style.boxMobileContentItem} key={`beampro_highlight_${index}`}>
                                    <div className={style.boxMobileContentItemImg}>
                                        <video autoPlay playsInline loop muted width="100%" >
                                            <source src={item.video} type="video/mp4" />
                                        </video>
                                    </div>

                                    <div className={style.itemTitle}>{item.title}</div>
                                    <div className={style.itemDesc}>{item.desc}</div>
                                    {!!item.tip && <div className={style.itemTip}>
                                        {item.tip}
                                    </div>}
                                </div>
                            ))
                        }
                    </div>
                </div>)}
        </>

    )
}

export default HighLights
import React from "react";
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

const fadeInUp = (target, trigger,distance,duration) => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.fromTo(target, {
        opacity: 0,
        y: !!distance?distance:60,
    },
        {
            opacity: 1,
            y: 0,
            duration: !!duration?duration:0.2,
            ease: 'power1.out',
            scrollTrigger: {
                trigger: trigger,
                start: "top 80%",
                end: "top 80%",
                toggleActions: "play none none reverse",
            }
        }

    )
}

export default fadeInUp